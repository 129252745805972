import type { AdDetails } from '../../../@types/adCommon.js';
import { ADPT_SF_METRICS_LOGGING } from '../weblabs.js';

type PostMetricDTO = {
    metricName: string;
    count?: number;
    dimensions: PostMetricDimensionsDTO;
};

type PostMetricDimensionsDTO = {
    app?: string;
    siteName?: string;
    slotName?: string;
    pageType?: string;
    subPageType?: string;
    placementId?: string;
    mediaType?: string;
    programId?: string;
    creativeTemplateName?: string;
    deviceType?: string;
    userAgent?: string;
};

/**
 * @deprecated This is not live yet. Don't use.
 * SIM: https://sim.amazon.com/issues/CPP-39529
 */
export const logMetric = (metricName: string, adDetails: AdDetails, count?: number): void => {
    if (ADPT_SF_METRICS_LOGGING().isT1() && navigator.sendBeacon) {
        navigator.sendBeacon(
            'https://e5qq6zj76g.execute-api.us-east-1.amazonaws.com/metric?x-api-key=aUAZqFtiIp6VxqpW3coqZ2JdZnV3RN9S8yKMRIdJ',
            JSON.stringify(toPostMetricDTO(metricName, count, toDimensions(adDetails))),
        );
    }
};

const toPostMetricDTO = (
    metricName: string,
    count: number | undefined,
    dimensions: PostMetricDimensionsDTO,
): PostMetricDTO => ({
    metricName: metricName,
    count: count,
    dimensions: dimensions,
});

const toDimensions = (adDetails: AdDetails): PostMetricDimensionsDTO => {
    return {
        app: 'SF',
        siteName: adDetails.siteName,
        slotName: adDetails.slotName,
        pageType: adDetails.pageType,
        subPageType: adDetails.subPageType,
        placementId: adDetails.placementId,
        mediaType: adDetails.mediaType,
        programId: adDetails.adCreativeMetaData?.adProgramId,
        creativeTemplateName: adDetails.adCreativeMetaData?.adCreativeTemplateName,
        deviceType: adDetails.deviceType,
        userAgent: navigator.userAgent,
    };
};
