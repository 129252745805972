import type { AdDetails } from '../../@types/adCommon.js';
import type { SafeFrameClient } from '../components/safeFrame.js';
import { ClientMessageSender } from './clientMessageSender.js';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS.js';
import { InternalSFClientAPI } from './InternalSFClientAPI.js';
import { CREATIVE_WRITTEN, RENDER_END, RENDER_START } from '../components/counters/AD_LOAD_COUNTERS.js';
import type { CommonSupportedCommands } from '../host/CommonSupportedCommands.js';
import { ADPT_SF_BTR_PIXEL_997225, ADPT_SF_BTR_SIMPLE } from '../components/weblabs.js';
import { isImmediateOrEmpty } from '../host/components/immediate.js';
import { COUNT_ON_DOWNLOAD_CLIENT, MEASUREMENT_METHODS } from './MEASUREMENT_METHODS.js';
import { PUBLISHER_JSON_KEY } from '../components/aax/aax.js';
import {
    ClientReporter,
    createPixelUrl,
    evaluateImpressionMeasurementMethod,
    tryWaitForBTR,
} from './clientReporter.js';
import { CommonSFClientSetupState, generateSyntheticLoadEvent } from './CommonSFSetup.js';
import { markScriptAsFired, waitUntilCreativeScriptsSyncExecuted } from './render.util.js';
import { logMetric } from '../components/metrics/aws-metric-service.js';

/*
 * Helper function to prepare creative template name for tagging
 * 1. Change to lower case
 * 2. Remove all non-alphanumeric characters
 * 3. Use 'unknown' if it does not exist
 */
export const prepareCreativeTemplateNameForTagging = (creativeTemplateName: string): string => {
    if (creativeTemplateName) {
        return creativeTemplateName.toLowerCase().replace(/[^0-9a-z]/g, '');
    }

    return 'unknown';
};

export const tagRenderFlow = (c: InternalSFClientAPI, o: AdDetails, cms: ClientMessageSender) => {
    if (o && o.adCreativeMetaData && Object.keys(o.adCreativeMetaData).length !== 0) {
        const creativeTemplateName = prepareCreativeTemplateNameForTagging(o.adCreativeMetaData.adCreativeTemplateName);
        c.addCsmTag('adrender');
        c.addCsmTag('adrender:safeframe');
        c.addCsmTag('adrender', 'creativetemplatename:' + creativeTemplateName);
        cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
            adrender: 'true',
            creativeTemplateName: creativeTemplateName,
            isLightAds: 'false',
        });

        if (o.adCreativeMetaData.adCreativeId) {
            c.addCsmTag('adrender', 'creativeid:' + o.adCreativeMetaData.adCreativeId);
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                creativeId: o.adCreativeMetaData.adCreativeId,
            });
        }

        if (o.adCreativeMetaData.adProgramId) {
            c.addCsmTag('adrender', 'programid:' + o.adCreativeMetaData.adProgramId);
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                adProgramId: o.adCreativeMetaData.adProgramId,
            });
        }

        if (typeof o.isCardsFlow !== 'undefined') {
            cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', {
                isCardsFlow: o.isCardsFlow.toString(),
            });
        }
    } else {
        c.addCsmTag('noadrender');
        cms.sendMessage<CommonSupportedCommands['addCsaEntity']>('addCsaEntity', { adrender: 'false' });
    }
};

/**
 * This method can be called multiple times during the lifecycle
 */
export const documentWrite = async (
    htmlContent: string,
    state: CommonSFClientSetupState,
    client: SafeFrameClient,
    o: AdDetails,
    cms: ClientMessageSender,
    cr: ClientReporter,
    c: InternalSFClientAPI,
) => {
    // document.write called by the creative can cause this to be called multiple times so we only register this the
    // first time
    // TODO Find a way to model maximizing viewability instead of firePixelsAfter
    // If firePixelsAfter is set then we don't want to cache the impression since it is intentionally
    // set in order to separate a render from if we should report the render
    // If ADPT_SF_BTR_PIXEL_997225 we fire impression pixel and do not need to cache.

    if (ADPT_SF_BTR_PIXEL_997225().isT1()) {
        if (!state.hasFiredCODPixel) {
            if (o.firePixelsAfter !== null && isImmediateOrEmpty(o.firePixelsAfter)) {
                cr.fireImpressionPixel(COUNT_ON_DOWNLOAD_CLIENT, false);
            } else {
                cms.sendMessage<CommonSupportedCommands['fireImpressionWithDelay']>('fireImpressionWithDelay');
            }
            state.hasFiredCODPixel = true;
        }
    } else {
        if (!state.hasCODFallbackRegistered && !o.firePixelsAfter) {
            // We want to make sure we register the COD fallback if we fail to send signal
            // TODO: https://taskei.amazon.dev/tasks/e0684d56-d5ef-4be0-9d6d-14203b3eba78 clean up COD fallback
            cms.sendMessage<CommonSupportedCommands['registerCODFallback']>(
                'registerCODFallback',
                createPixelUrl(o.aaxImpPixelUrl, PUBLISHER_JSON_KEY, COUNT_ON_DOWNLOAD_CLIENT),
            );
            state.hasCODFallbackRegistered = true;
        }
    }

    await renderHtml(htmlContent, client, c);
};

export const renderHtml = async (htmlContent: string, client: SafeFrameClient, clientApi: InternalSFClientAPI) => {
    clientApi.countMetric(RENDER_START, 1);
    clientApi.logCsaEvent(AD_LOAD_EVENTS.RENDER_START);

    // We add an event listener if needed to track when the replay is all done
    const eventFired = waitUntilCreativeScriptsSyncExecuted();

    // The below conditional checks if an inline script is running AND that document has not
    // finished painting. In this case, document.write should append to the document immediately
    // below that script, as described here: https://www.oreilly.com/library/view/javascript-the-definitive/0596000480/re204.html
    // In all other cases we will replace the contents of the document with the string passed to document.write.

    if (document.currentScript !== null) {
        let currentElement: Element | null = document.currentScript;
        currentElement.insertAdjacentHTML('afterend', htmlContent);

        // Scripts do not execute when added via html string. Need to replay each script after the
        // insertion point (via next sibling). If we used replayAll on the body, it would infinitely
        // loop by replaying the script which called renderHTML in the first place.
        currentElement = currentElement.nextElementSibling;
        while (currentElement !== null) {
            if (currentElement.tagName === 'SCRIPT') {
                replayScriptTag(currentElement as HTMLScriptElement);
            } else {
                replayAllDescendantScriptsSerially(currentElement as HTMLElement);
            }
            currentElement = currentElement.nextElementSibling;
        }
    } else {
        writeHtmlToDocumentRoot(htmlContent);
        addFinishProcessingEvent();
        replayAllDescendantScriptsSerially(document.documentElement);
    }

    client.ensureGlobals();
    await eventFired;
    await waitUntilImagesPartiallyLoadedOrComplete();
    // We record the render complete time as when all the sync scripts have parsed.  We do this because not all scripts will have static images
    // so can't just wait until images are loaded.  This is a judgement call however since some images theoretically could load after the script.
    client.renderCompleteTime = new Date();

    clientApi.countMetric(RENDER_END, 1);
    clientApi.logCsaEvent(AD_LOAD_EVENTS.RENDER_END);
};

export const creativeReplayAttr = 'iscomplete';
export const creativeReplayId = 'creativeReplayStatus';
export const creativeReplayCompleteEventName = 'creativeReplayComplete';
const fireEvent = `document.dispatchEvent(new CustomEvent("${creativeReplayCompleteEventName}"));`;

// We need to send even when the doc is finished serial processing
const addFinishProcessingEvent = () => {
    document.body.innerHTML += `<script id="${creativeReplayId}">${fireEvent}</script>`;
};

export const replayScriptTag = (adScript: HTMLScriptElement): void => {
    const range = document.createRange();
    range.selectNode(adScript);
    const adScriptCopyDoc = range.createContextualFragment(adScript.outerHTML);
    const adScriptCopy = adScriptCopyDoc.firstElementChild as HTMLScriptElement;
    if (adScriptCopy.getAttribute('src')) {
        // https://html.spec.whatwg.org/multipage/scripting.html#attr-script-async
        // Need to explicity force async to false to skip https://html.spec.whatwg.org/multipage/scripting.html#script-processing-model
        // https://hsivonen.fi/script-execution/
        adScriptCopy.async = false;
    } else {
        const scriptText = adScript.innerHTML;
        adScriptCopy.async = false;
        adScriptCopy.setAttribute('src', `data:text/javascript;charset=UTF-8,${encodeURIComponent(scriptText)}`);
    }
    adScript.parentNode?.insertBefore(adScriptCopy, adScript.nextSibling);
    adScript.parentNode?.removeChild(adScript);
};

/**
 * Replaces the contents of the document with the passed HTML string
 * @param htmlContent string of HTML to add
 */
export const writeHtmlToDocumentRoot = (htmlContent: string): void => {
    // setting innerHtml drops the host element's attributes, ie <html lang="en">
    // setting the outerHtml solves this, but setting outerHtml on document.documentElement is forbidden by the spec
    // supporting a target of document.documentElement is a must

    // Funciton fails with strict equality
    // eslint-disable-next-line eqeqeq
    if (document.documentElement == undefined) {
        const html = document.createElement('html');
        document.appendChild(html);
    }
    document.documentElement.innerHTML = htmlContent;

    // options for getting attributes from the html string include DOMParser or regex, but performance is a concern
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes#enumerating_elements_attributes
};

/**
 * Executes all of the descendant inline scripts of the passed element in the order in which they appear
 * via depth first, pre-order DOM tree traversal
 */
export const replayAllDescendantScriptsSerially = (containerElement: HTMLElement): void => {
    // https://html.spec.whatwg.org/multipage/scripting.html#list-of-scripts-that-will-execute-in-order-as-soon-as-possible
    // We cannot use createFragment because it is required to import in the body so we lose the HEAD/BODY/etc. tags
    // So we use InnerHTML which will not execute the scripts but then dynamically recreate the script tags
    // so that they will be executed (as they are not with innerHTML imported tags)
    const adScripts = containerElement.querySelectorAll('script');
    for (const adScript of adScripts) {
        replayScriptTag(adScript);
    }
};

//https://html.spec.whatwg.org/multipage/images.html#img-inc
const imageIsDownloading = (img: HTMLImageElement) => img.naturalWidth > 0 || img.naturalHeight > 0;
const imageIsTrackingPixel = (img: HTMLImageElement) => img.height === 0 || img.width === 0;
const isNotCompleteOrPartiallyLoadedOrTrackingPixel = (img: HTMLImageElement) => {
    !img.complete || imageIsDownloading(img) || imageIsTrackingPixel(img);
};
// This simulates the resource loading requirement of the load event that will be fired
// It is slightly difference in that it only does images, ignores tracking pixels and returns when
// the images are partially available
export async function waitUntilImagesPartiallyLoadedOrComplete(): Promise<void> {
    const images = document.querySelectorAll('img');
    const imagePromises = Array.from(images)
        .filter(isNotCompleteOrPartiallyLoadedOrTrackingPixel)
        .map(
            (img) =>
                new Promise<void>((resolve) => {
                    img.addEventListener(
                        'load',
                        () => {
                            resolve();
                        },
                        { once: true },
                    );
                }),
        );
    await Promise.all(imagePromises);
}

export const postCreativeWrite = async (
    htmlContent: string,
    o: AdDetails,
    cms: ClientMessageSender,
    cr: ClientReporter,
    c: InternalSFClientAPI,
) => {
    // Since the initial loading event was fired when loading the messageport we need to simulate them
    // for the creative
    // TODO(https://sim.amazon.com/issues/CPP-38246) Wait for creative image resource loads in corner case
    fireDOMContentLoadedEventForCreative();
    // TODO(https://sim.amazon.com/issues/CPP-38245) Check if this can be moved earlier
    fireLoadEventForCreativeSinceItWasAlreadyFiredUpon();

    // isNoInventory is always false at this point
    logMetric('impression_BTR_request', o, performance.now());
    const measurementMethod: MEASUREMENT_METHODS = ADPT_SF_BTR_SIMPLE().isT1()
        ? await tryWaitForBTR()
        : await evaluateImpressionMeasurementMethod(htmlContent);
    logMetric('impression_BTR_response', o, performance.now());
    if (ADPT_SF_BTR_PIXEL_997225().isT1()) {
        cms.sendMessage<CommonSupportedCommands['startBTRPixelTracking']>('startBTRPixelTracking', {
            isNoInventory: false,
            measurementMethod: measurementMethod,
        });
    } else {
        cms.sendMessage<CommonSupportedCommands['startPixelTracking']>('startPixelTracking', {
            isNoInventory: false,
            measurementMethod: measurementMethod,
        });
    }

    // Metric for image rendered, to be assessed for impression firing on broken creatives
    if (o.adCreativeMetaData) {
        cr.fireImageLoaded(o.adCreativeMetaData.adCreativeTemplateName);
    }
    c.countMetric(CREATIVE_WRITTEN, 1);
    c.logCsaEvent(AD_LOAD_EVENTS.CREATIVE_WRITTEN);
    workAroundIOBugtt0156195085();
};

const fireLoadEventForCreativeSinceItWasAlreadyFiredUpon = () => {
    markScriptAsFired('creativeLoad');
    generateSyntheticLoadEvent();
};
const fireDOMContentLoadedEventForCreative = () => {
    markScriptAsFired('creativedomcontentloaded');
    document.dispatchEvent(new Event('DOMContentLoaded'));
};

const workAroundIOBugtt0156195085 = () => {
    // allows clicks to propagate iframe in iOS
    // https://tt.amazon.com/0156195085
    // TODO Verify this is still needed.
    document.addEventListener(
        'touchstart',
        {
            handleEvent: () => {
                //do nothing
            },
        },
        false,
    );
};
