import { initDcm } from './dcm.js';
import type { SFClientAPI, SFClientVideoAPI } from '@amzn/safe-frame-client-amzn';
import type { InternalSFClientAPI } from './InternalSFClientAPI.js';
import type { MobileSFClientAPI } from '@amzn/safe-frame-client-mobilesfclient';
import { errorLog } from './commonSf.js';
import { MSFClientSetup } from './MSFClientSetup.js';
import type { DesktopSFClientAPI } from '@amzn/safe-frame-client-desktopsfclient';
import type { SafeFrameClient } from '../components/safeFrame.js';
import { SFClientSetup } from './SFClientSetup.js';
import type { AdDetails } from '../../@types/adCommon.js';
import { readyMessage } from '../components/messaging/bootstrap.js';
import { logFatal, logError } from '@amzn/apejs-instrumentation/src/metrics/logging.js';
import type { SetupVideoPlayer } from './video.js';
import { isReferrerAllowed } from './security/referrers.js';

// We bootstrap the client by waiting for the server to send us a MessagePort
// https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API
export const initWithMessagePort = (safeFrameClientFactory: SafeFrameClientFactory): void => {
    if (!isReferrerAllowed()) {
        logFatal(`Referrer not allowed: ${document.referrer}`);
        return;
    }
    const adDetails = getAdDetailsFromIFrameName();
    if (adDetails) {
        const mc = new MessageChannel();
        init(safeFrameClientFactory, mc.port1, adDetails);
        notifyParentWithMessagePort(mc.port2);
    } else {
        logError('Ad Details not found in name!');
    }
};

const init = async (
    safeFrameClientFactory: SafeFrameClientFactory,
    messagePort: MessagePort,
    iframeNameAdDetails: AdDetails,
): Promise<void> => {
    try {
        initDcm();
        const adDetails = iframeNameAdDetails;
        if (adDetails.forcePunt) {
            return;
        }
        // FIX ME: we should not have a stringified obj inside the stringified AdDetails. Fix this upstream
        window.renderingWeblabs = JSON.parse(adDetails.weblabTreatments ?? '');
        performance?.mark?.('SafeFrame: SF received ad details');
        const safeFrameClient = safeFrameClientFactory(messagePort, adDetails);
        try {
            safeFrameClient.initSFClient();
        } catch (ex) {
            safeFrameClient.c.logError('Could not init SafeFrame Client', ex as Error);
        }
    } catch (ex) {
        errorLog('Could not init ', ex as Error);
    }
};

export type SafeFrameClientFactory = (messagePort: MessagePort, adDetails: AdDetails) => SafeFrameClient;
export const sfClientSetupFactory: SafeFrameClientFactory = (
    messagePort: MessagePort,
    adDetails: AdDetails,
    setupVideoPlayer?: SetupVideoPlayer,
) => {
    const sfClient: SFClientSetup = new SFClientSetup(adDetails, messagePort, setupVideoPlayer);
    window.SFClient = sfClient.c as SFClientVideoAPI & SFClientAPI & InternalSFClientAPI & DesktopSFClientAPI;
    return sfClient;
};
export const msfClientSetupFactory: SafeFrameClientFactory = (
    messagePort: MessagePort,
    adDetails: AdDetails,
    setupVideoPlayer?: SetupVideoPlayer,
) => {
    const msfClient: MSFClientSetup = new MSFClientSetup(adDetails, messagePort, setupVideoPlayer);
    window.MSFClient = msfClient.c as SFClientVideoAPI & SFClientAPI & InternalSFClientAPI & MobileSFClientAPI;
    return msfClient;
};

// We use this to notify the parent the frame is ready to accept messages
// Previously we used onload but this doesn't always find due to hidden
// e.g. https://stackoverflow.com/questions/12410606/hidden-iframe-onload-event
const notifyParentWithMessagePort = (mp: MessagePort) => {
    performance?.mark?.(`SafeFrame: Start Send Ready ${mp}`);
    // Use this to work around https://github.com/jsdom/jsdom/issues/2745
    try {
        // This will fail in the normal browser but work in JSDOM
        window.parent.dispatchEvent(
            new MessageEvent('message', {
                source: window,
                origin: window.location.origin,
                ports: [mp],
                data: readyMessage,
            }),
        );
    } catch {
        window.parent.postMessage(readyMessage, '*', [mp]);
    }
};

const getAdDetailsFromIFrameName = (): AdDetails | null => {
    return window.name !== '' ? JSON.parse(window.name) : null;
};
